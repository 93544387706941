/* App.css - Basic styling for the application */

.App {
  text-align: center;
}

/* Default styling for links */
a {
  text-decoration: none;
}

/* Fix for potential overflow issues */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

/* Auth pages background */
.auth-wrapper {
  background-color: #f8f9fa;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
} 